import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../_services/session.service';
import { environment } from 'projects/disc/src/environments/environment';

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss']
})
export class AutoLoginComponent {

  sub;

  constructor(public session: SessionService) { }

  ngOnInit() {
    this.Login();
  }

  Login() {
    this.sub = setTimeout(async () => {
      try {
        await this.session.Login({
          username: environment.user.name,
          password: environment.user.pwd
        }).toPromise();
        this.Login();
      } catch (error) {
        this.sub = setTimeout(() => {
          this.Login();
        }, 5000);
      }
    }, this.session.secondExpire());
  }
}
