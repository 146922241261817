import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { SessionService } from '../_services/session.service';
import { DiscService } from '../_services/disc.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  data = {
    rut: null,
    nombre: null,
    apellido: null,
    type: null,
    url: null,
    rubrica: null,
    empresa: null,
    token: null,
    contrato: null
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private session: SessionService,
    private discService: DiscService
  ) { }
  sub;

  ngOnInit() {
    this.route.queryParamMap.subscribe(query => {
      this.data.rut = query.get('rut');
      // this.data.token = query.get('token');
      this.data.nombre = query.get('nombre');
      this.data.apellido = query.get('apellido');
      this.data.type = query.get('type');
      this.data.url = query.get('url');
      this.data.empresa = query.get('empresa');
      this.data.rubrica = query.get('rubrica');
      this.data.contrato = query.get('contrato');
      localStorage.setItem('scope', JSON.stringify(this.data));
    });


    this.validateSession(this.data.token);

    // if (this.session.isValid) {
    //   this.router.navigate(['evaluation'])
    // } else {
    //   this.sub = this.session.onLoginSuccess.subscribe(() => {
    //     this.router.navigate(['evaluation'])
    //   })
    // }
  }

  validateSession(token) {
    // this.session.validateToken(token)
    //   .subscribe((data) => {
        if (this.session.isValid) {
          this.discService.setValidate(true);
          this.router.navigate(['evaluation']);
        } else {
          this.sub = this.session.onLoginSuccess.subscribe(() => {
            this.discService.setValidate(true);
            this.router.navigate(['evaluation']);
          });
        }
      // }, () => {
      //   this.router.navigate(['error_401']);
      // });
  }

}
