import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Evaluacion } from 'projects/evaluacion/src/app/_class/evaluacion';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class FichaService {

  constructor(
    private http: HttpClient,
    private session: SessionService
  ) { }

  private resource = `${environment.host.endpoint}/v1/ficha`;

  public view(fichaID, params= null) {
    const headers = { Authorization: `${this.session.credential.token_type} ${this.session.credential.token}` };
    return this.http.get(`${this.resource}/${fichaID}`, { headers, params });
  }
}
