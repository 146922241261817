import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { DiscComponent } from './disc/disc.component';
import { Error401Component } from './page/error/error401/error401.component';


const routes: Routes = [
  { path: 'register', component: RegisterComponent },
  { path: 'error_401', component: Error401Component },
  { path: 'evaluation', component: DiscComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
