import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Evaluacion } from 'projects/evaluacion/src/app/_class/evaluacion';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class EvaluacionService {

  constructor(
    private http: HttpClient,
    private session: SessionService
  ) { }

  private reosurceEvaluacion = `${environment.host.endpoint}/v1/disc/evaluacion`;
  private reosurceFicha = `${environment.host.endpoint}/v1/ficha/evaluacion`;

  private keyStorage = 'DiscDetail';
  public async getEvaluacion() {
    if (localStorage.getItem(this.keyStorage)) {
      return JSON.parse(atob(localStorage.getItem(this.keyStorage))) as Evaluacion;
    } else {
      const evaluacion = await this.http.get<Evaluacion>(this.reosurceEvaluacion,  {
        headers: {
          Authorization: `${this.session.credential.token_type} ${this.session.credential.token}`
        }
      }).toPromise();
      localStorage.setItem(this.keyStorage, btoa(JSON.stringify(evaluacion)));
      return evaluacion;
    }
  }

  public SaveFicha(fichaData) {
    const headers = { Authorization: `${this.session.credential.token_type} ${this.session.credential.token}` };
    return this.http.post(this.reosurceFicha, fichaData, { headers });
  }
}
