import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent {

  text = '';

  constructor() {
    const { name, version, environment: e } = environment.app;
    this.text = ` ${e} ${name} ${version}`;
  }
}
