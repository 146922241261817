import { User } from './user';
import { Client } from './client';

export class Authentication {

    constructor(
        public user: User,
        public client: Client,
        public grant_type: string = 'password'
    ) { }

    Body() {
        return {
            username: this.user.username,
            password: this.user.password,
            client_id: this.client.name,
            client_secret: this.client.secret,
            grant_type: this.grant_type
        };
    }
}
