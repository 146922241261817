import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { EvaluacionRespuesta } from '../_class/evaluacion-respuesta';



@Injectable({
  providedIn: 'root'
})

export class DiscService {

  private validate = false;
  private resourceDisc = 'CurrentDisc';
  private datos: any = {
    numeroPregunta: 0,
    respuesta: []
  };
  constructor(private http: HttpClient) {
    this.getState;
  }

  public getValidate() {
    return this.validate;
  }

  public setValidate(validate) {
    this.validate = validate;
  }

  public getState() {
    if (localStorage[this.resourceDisc]) {
      this.datos = JSON.parse(localStorage[this.resourceDisc]);
    }
    return this.datos;
  }

  public setState() {
    localStorage[this.resourceDisc] = JSON.stringify(this.datos);

  }

  public clearState() {
    localStorage.removeItem(this.resourceDisc);
  }

  public addRespuesta(numeroPregunta: number, respuestas: EvaluacionRespuesta[]) {
    this.datos.numeroPregunta = numeroPregunta;
    this.datos.respuesta = this.datos.respuesta || [];
    this.datos.respuesta.push(...respuestas);
    this.setState();
  }

  public getRespuesta() {
    return this.datos.respuesta;
  }
}
